@import '@/components/mixins.scss';

label {
	margin: 0px !important;
}

:global(.ant-form-item) {
	margin-bottom: 10px !important;
}

:global(.btnPasswordGenerator) {
	padding-top: 30px !important;
}
